import React from 'react'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import styled from 'styled-components'

const UI = {
  FieldContainer: styled.div`
    margin-top: 0.5em;
    position: relative;
  `,
  Label: styled.div`
    font-size: 0.75em;
    position: absolute;
    width: 100%;
    left-align: left;
    top: -7px;
    left: 10px;
    color: #B6C3CE;
  `,
  Field: styled.div`
    margin-top: 18.5px;
    padding: 0 14px 18.5px 14px;
    font-size: 1em;
    line-height: 1.1875em;
    min-height: 56px;
    word-break: break-all;
    white-space: ${(props) => props.whitespace ? props.whitespace : 'normal'};
    max-height: ${(props) => props.maxHeight ? props.maxHeight : '100px'};
    overflow: auto;
  `
}

export default class Component extends React.Component {
  render() {
    return (
      <FormGroup row>
        <FormControl
          fullWidth
          margin="dense">
          <UI.FieldContainer>
            <UI.Label>
              {this.props.label}
            </UI.Label>
            <UI.Field
              whitespace={this.props.whitespace}
              maxHeight={this.props.maxHeight}>
              {this.props.value}
            </UI.Field>
          </UI.FieldContainer>
        </FormControl>
      </FormGroup>
    )
  }
}
