import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Grid from '@material-ui/core/Grid'

export default class Component extends React.Component {
  render() {
    return (
      <Grid
        style={{
          width: '450px',
          margin: '0 auto 2em auto'
        }}
        item>
        <Card>
          <CardHeader
            title={this.props.title}
            subheader={this.props.subheader} />
          <CardContent>
            {this.props.children}
          </CardContent>
        </Card>
      </Grid>
    )
  }
}
