import React from 'react'
import Card from './Card'
import Link from '@material-ui/core/Link'
import TextFieldFormGroup from './TextFieldFormGroup'
import TextValueFormGroup from './TextValueFormGroup'
import ButtonFormGroup from './ButtonFormGroup'
import toWei from '@authereum/utils/core/toWei'
import localStorage from '../storage'

export default class Component extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      transactions: [],
      to: localStorage.getItem('estimateGasBatch::to') || '0x22d491Bde2303f2f43325b2108D26f1eAbA1e32b',
      value: localStorage.getItem('estimateGasBatch::value') || '0.01',
      data: localStorage.getItem('estimateGasBatch::data') || '',
      gasPrice: localStorage.getItem('estimateGasBatch::gasPrice') || '',
      gasLimit: localStorage.getItem('estimateGasBatch::gasLimit') || ''
    }
  }

  render() {
    const { transactions } = this.state

    return (
      <Card
        title={'Estimate Gas Batch'}
        subheader={'web3.currentProvider.estimateGasBatch([{from, to, value, data}, ...])'}>
        <TextFieldFormGroup
          label="To"
          placeholder="Enter to address"
          value={this.state.to}
          onChange={event => {
            const to = event.target.value
            localStorage.setItem('estimateGasBatch::to', to)
            this.setState({ to })
          }}
        />
        <TextFieldFormGroup
          label="Value (ETH)"
          placeholder="Enter ETH value"
          value={this.state.value}
          onChange={event => {
            const value = event.target.value
            localStorage.setItem('estimateGasBatch::value', value)
            this.setState({ value })
          }}
        />
        <TextFieldFormGroup
          label="Data"
          multiline={true}
          rowsMax={5}
          placeholder="Enter data"
          value={this.state.data}
          onChange={event => {
            const data = event.target.value
            localStorage.setItem('estimateGasBatch::data', data)
            this.setState({data})
          }}
        />
        <TextFieldFormGroup
          label="Gas Price"
          placeholder="Enter gas price"
          value={this.state.gasPrice}
          onChange={event => {
            const gasPrice = event.target.value
            localStorage.setItem('estimateGasBatch::gasPrice', gasPrice)
            this.setState({gasPrice})
          }}
        />
        <TextFieldFormGroup
          label="Gas Limit"
          placeholder="Enter gas limit"
          value={this.state.gasLimit}
          onChange={event => {
            const gasLimit = event.target.value
            localStorage.setItem('estimateGasBatch::gasLimit', gasLimit)
            this.setState({gasLimit})
          }}
        />
        <ButtonFormGroup
          variant="outlined"
          onClick={this.handleAddTx}
        >
          Add transaction to batch
        </ButtonFormGroup>
        <TextValueFormGroup
          label={
          <>
            Batched transactions {
              transactions.length ?
                <>
                  <span>({transactions.length}) </span>
                  <Link href='#clear' onClick={this.handleClearBatch}>clear</Link>
                </>
                : null
              }
          </>
          }
          readOnly={true}
          whitespace={"pre"}
          value={transactions.length ? JSON.stringify(transactions, null, 2) : ''}
        />
        <TextValueFormGroup
          label="Gas batch estimate"
          value={this.props.estimatedGas}
        />
        <ButtonFormGroup
          onClick={this.handleSubmit}
        >
          Estimate gas batch
        </ButtonFormGroup>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)

    this.setState({
      transactions: []
    })
  }

  handleClearBatch = (event) => {
    event.preventDefault()

    this.setState({
      transactions: []
    })
  }

  handleAddTx = (event) => {
    event.preventDefault()
    const { transactions, to, value, data, gasPrice, gasLimit } = this.state
    const tx = {}

    if (to) tx.to = to
    if (value) tx.value = toWei(value, 18)
    if (data) tx.data = data
    if (gasPrice) tx.gasPrice = gasPrice
    if (gasLimit) tx.gasLimit = gasLimit

    transactions.push(tx)

    this.setState({
      transactions
    })
  }
}
