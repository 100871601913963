import React from 'react'
import Card from './Card'
import TextFieldFormGroup from './TextFieldFormGroup'
import TextValueFormGroup from './TextValueFormGroup'
import ButtonFormGroup from './ButtonFormGroup'
import localStorage from '../storage'

export default class Component extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      token: localStorage.getItem('nftSafeTransferFrom::token') || '0x4f41d10f7e67fd16bde916b4a6dc3dd101c57394',
      from: localStorage.getItem('nftSafeTransferFrom::from') || '0x22d491Bde2303f2f43325b2108D26f1eAbA1e32b',
      to: localStorage.getItem('nftSafeTransferFrom::to') || '0x96E782B8CCC76F239043A3aE5Ae17cbB67399aA2',
      data: localStorage.getItem('nftSafeTransferFrom::data') || '0x00',
      tokenId: localStorage.getItem('nftSafeTransferFrom::tokenId') || '8117',
    }
  }

  render() {
    return (
      <Card
        title={'ERC721: Safe Transfer From'}
        subheader={'token.safeTransferFrom(from, to, tokenId, data)'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label="Token"
            placeholder="Enter token address"
            value={this.state.token}
            onChange={event => {
              const token = event.target.value
              localStorage.setItem('nftSafeTransferFrom::token', token)
              this.setState({token})
            }}
          />
          <TextFieldFormGroup
            label="From"
            placeholder="Enter from address"
            value={this.state.from}
            onChange={event => {
              const from = event.target.value
              localStorage.setItem('nftSafeTransferFrom::from', from)
              this.setState({from})
            }}
          />
          <TextFieldFormGroup
            label="To"
            placeholder="Enter to address"
            value={this.state.to}
            onChange={event => {
              const to = event.target.value
              localStorage.setItem('nftSafeTransferFrom::to', to)
              this.setState({to})
            }}
          />
          <TextFieldFormGroup
            label="Data"
            multiline={true}
            rowsMax={10}
            placeholder="Enter data"
            value={this.state.data}
            onChange={event => {
              const data = event.target.value
              localStorage.setItem('nftSafeTransferFrom::data', data)
              this.setState({data})
            }}
          />
          <TextFieldFormGroup
            label="Token ID"
            placeholder="Enter token ID"
            value={this.state.tokenId}
            onChange={event => {
              const tokenId = event.target.value
              localStorage.setItem('nftSafeTransferFrom::tokenId', tokenId)
              this.setState({tokenId})
            }}
          />
          <TextValueFormGroup
            label="Transaction hash"
            value={this.props.transactionHash}
          />
          <ButtonFormGroup>
            Safe transfer
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
