import React from 'react'
import Card from './Card'
import TextFieldFormGroup from './TextFieldFormGroup'
import TextValueFormGroup from './TextValueFormGroup'
import ButtonFormGroup from './ButtonFormGroup'
import localStorage from '../storage'

export default class Component extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      token: localStorage.getItem('nftApprove::token') || '0x4f41d10f7e67fd16bde916b4a6dc3dd101c57394',
      to: localStorage.getItem('nftApprove::to') || '0x96E782B8CCC76F239043A3aE5Ae17cbB67399aA2',
      tokenId: localStorage.getItem('nftApprove::tokenId') || '8117',
    }
  }

  render() {
    return (
      <Card
        title={'ERC721: Approve'}
        subheader={'token.approve(to, tokenId)'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label="Token"
            placeholder="Enter token address"
            value={this.state.token}
            onChange={event => {
              const token = event.target.value
              localStorage.setItem('nftApprove::token', token)
              this.setState({token})
            }}
          />
          <TextFieldFormGroup
            label="To"
            placeholder="Enter to address"
            value={this.state.to}
            onChange={event => {
              const to = event.target.value
              localStorage.setItem('nftApprove::to', to)
              this.setState({to})
            }}
          />
          <TextFieldFormGroup
            label="Token ID"
            placeholder="Enter token ID"
            value={this.state.tokenId}
            onChange={event => {
              const tokenId = event.target.value
              localStorage.setItem('nftApprove::tokenId', tokenId)
              this.setState({tokenId})
            }}
          />
          <TextValueFormGroup
            label="Transaction hash"
            value={this.props.transactionHash}
          />
          <ButtonFormGroup>
            Approve
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
