import React from 'react'
import Card from './Card'
import TextFieldFormGroup from './TextFieldFormGroup'
import TextValueFormGroup from './TextValueFormGroup'
import ButtonFormGroup from './ButtonFormGroup'
import localStorage from '../storage'

export default class Component extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: localStorage.getItem('signTypedDataV3:data') || JSON.stringify({
        types: {
          EIP712Domain: [
            { name: 'name', type: 'string' },
            { name: 'version', type: 'string' },
            { name: 'chainId', type: 'uint256' },
            { name: 'verifyingContract', type: 'address' }
          ],
          Person: [
            { name: 'name', type: 'string' },
            { name: 'wallet', type: 'address' }
          ],
          Mail: [
            { name: 'from', type: 'Person' },
            { name: 'to', type: 'Person' },
            { name: 'contents', type: 'string' }
          ]
        },
        primaryType: 'Mail',
        domain: {
          name: 'Ether Mail',
          version: '1',
          chainId: 1,
          verifyingContract: '0xCcCCccccCCCCcCCCCCCcCcCccCcCCCcCcccccccC'
        },
        message: {
          from: {
            name: 'Cow',
            wallet: '0xCD2a3d9F938E13CD947Ec05AbC7FE734Df8DD826'
          },
          to: {
            name: 'Bob',
            wallet: '0xbBbBBBBbbBBBbbbBbbBbbbbBBbBbbbbBbBbbBBbB'
          },
          contents: 'Hello, Bob!'
        }
      }, null, 2)
    }
  }

  render() {
    return (
      <Card
        title={'Sign Typed Data V3'}
        subheader={`provider.sendAsync({method: 'eth_signTypedData', params})`}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label="JSON"
            multiline={true}
            rowsMax={10}
            placeholder="Enter JSON data"
            value={this.state.data}
            onChange={event => {
              const data = event.target.value
              this.setState({data})
              localStorage.setItem('signTypedDataV3:data', data)
            }}
          />
          <TextValueFormGroup
            label="Signature"
            value={this.props.signature}
          />
          <ButtonFormGroup>
            Sign typed data
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
