import React from 'react'
import Card from './Card'
import TextValueFormGroup from './TextValueFormGroup'
import ButtonFormGroup from './ButtonFormGroup'

export default class Component extends React.Component {
  render() {
    return (
      <Card
        title={'Disable'}
        subheader={'web3.currentProvider.disable()'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextValueFormGroup
            label="Disabled"
            value={`${this.props.disabled !== undefined ? this.props.disabled: '-'}`.toUpperCase()}
          />
          <ButtonFormGroup
            disabled={this.props.disabled}
          >
            Disable
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
