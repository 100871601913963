import React from 'react'
import Card from './Card'
import TextFieldFormGroup from './TextFieldFormGroup'
import TextValueFormGroup from './TextValueFormGroup'
import ButtonFormGroup from './ButtonFormGroup'
import localStorage from '../storage'

export default class Component extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      token: localStorage.getItem('tokenBalanceOf::token') || '0x7d669A64deb8a4A51eEa755bb0E19FD39CE25Ae9',
      account: localStorage.getItem('tokenBalanceOf::account') || '0x22d491Bde2303f2f43325b2108D26f1eAbA1e32b',
    }
  }

  render() {
    return (
      <Card
        title={'ERC20: Balance'}
        subheader={'token.balanceOf(account)'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label="Token"
            placeholder="Enter token address"
            value={this.state.token}
            onChange={event => {
              const token = event.target.value
              localStorage.setItem('tokenBalanceOf::token', token)
              this.setState({token})
            }}
          />
          <TextFieldFormGroup
            label="Account"
            placeholder="Enter account address"
            value={this.state.account}
            onChange={event => {
              const account = event.target.value
              this.setState({account})
              localStorage.setItem('tokenBalanceOf::account', account)
            }}
          />
          <TextValueFormGroup
            label="Balance"
            value={this.props.balance}
          />
          <ButtonFormGroup>
            Get balance
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
