import React from 'react'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Card from './Card'
import TextFieldFormGroup from './TextFieldFormGroup'
import TextValueFormGroup from './TextValueFormGroup'
import ButtonFormGroup from './ButtonFormGroup'
import localStorage from '../storage'

export default class Component extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: localStorage.getItem('etherConverter::value') || '1',
      unit: localStorage.getItem('etherConverter::unit') || 'ether',
    }
  }

  render() {
    return (
      <Card
        title={'Ether Converter'}
        subheader={`convert(value, '${this.state.unit}')`}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label="Value"
            placeholder="Enter unit value"
            value={this.state.value}
            onChange={event => {
              const value = event.target.value
              this.setState({value})
              localStorage.setItem('etherConverter::value', value)
            }}
          />
          <FormGroup row>
            <FormControl
              fullWidth
              margin="dense">
              <Select
                value={this.state.unit}
                onChange={this.handleUnitChange}
                variant="outlined"
                style={{
                  borderTop: '1px solid #d0d3d4',
                  borderRight: '1px solid #d0d3d4',
                  borderLeft: '1px solid #d0d3d4',
                  padding: '0.8em'
                }}>
                <MenuItem value='wei'>Wei</MenuItem>
                <MenuItem value='kwei'>Kwei</MenuItem>
                <MenuItem value='mwei'>Mwei</MenuItem>
                <MenuItem value='gwei'>Gwei</MenuItem>
                <MenuItem value='szabo'>Szabo</MenuItem>
                <MenuItem value='finney'>Finney</MenuItem>
                <MenuItem value='ether'>Ether</MenuItem>
                <MenuItem value='kether'>Kether</MenuItem>
                <MenuItem value='mether'>Mether</MenuItem>
                <MenuItem value='gether'>Gether</MenuItem>
                <MenuItem value='tether'>Tether</MenuItem>
              </Select>
            </FormControl>
          </FormGroup>
          <TextValueFormGroup
            label="Converted"
            whitespace="pre"
            maxHeight="auto"
            value={JSON.stringify(this.props.converted, null, 2)}
          />
          <ButtonFormGroup>
            Convert
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleUnitChange = (event) => {
    event.preventDefault()

    const unit = event.target.value
    this.setState({unit})

    localStorage.setItem('etherConverter::unit', unit)
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
