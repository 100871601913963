import React from 'react'
import Card from './Card'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'

export default class Component extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      enabled: true
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ enabled: props.enabled })
  }

  render() {
    return (
      <Card
        title={'Authereum: Show Widget'}
        subheader={`web3.currentProvider.showWidget(${this.state.enabled})`}>
        <form
          noValidate
          onSubmit={(event) => event.preventDefault()}>
          <FormControlLabel
            control={
              <Switch
                checked={this.state.enabled}
                color="primary"
                onChange={(event, enabled ) => {
                  this.props.onSubmit({ enabled })
                  this.setState({ enabled })
                }}
                value="enabled"
              />
            }
            label={`Press to ${this.state.enabled ? 'hide' : 'show'}`}
          />
        </form>
      </Card>
    )
  }
}
