import React from 'react'
import Card from './Card'
import TextFieldFormGroup from './TextFieldFormGroup'
import TextValueFormGroup from './TextValueFormGroup'
import ButtonFormGroup from './ButtonFormGroup'
import localStorage from '../storage'

export default class Component extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: localStorage.getItem('signTypedData::data') || JSON.stringify([
        {
          type: 'string',
          name: 'Name',
          value: 'Alice'
        },
        {
          type: 'uint32',
          name: 'Amount',
          value: '5000'
        }
      ], null, 2),
    }
  }

  render() {
    return (
      <Card
        title={'Sign Typed Data'}
        subheader={`provider.sendAsync({method: 'eth_signTypedData', params})`}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label="JSON"
            multiline={true}
            rowsMax={10}
            placeholder="Enter JSON data"
            value={this.state.data}
            onChange={event => {
              const data = event.target.value
              this.setState({data})
              localStorage.setItem('signTypedData::data', data)
            }}
          />
          <TextValueFormGroup
            label="Signature"
            value={this.props.signature}
          />
          <ButtonFormGroup>
            Sign typed data
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
