import versions from './authereumVersions'

const authereumVersions = versions.reverse()

let webUri = null
let apiUri = null
let rpcUri = null
let xsUri = null

let networkName = process.env.REACT_APP_NETWORK

let tld = 'authereum.com'

if (!networkName || networkName === 'localhost') {
  webUri = 'http://localhost:3000'
  apiUri = 'http://localhost:4000'
  rpcUri = `https://${networkName || 'kovan'}.rpc.${tld}`
  xsUri = 'http://localhost:3002'
  networkName = 'kovan'
} else if (networkName === 'dev') {
  webUri = `https://dev.${tld}`
  apiUri = `https://dev.api.${tld}`
  rpcUri = `https://kovan.rpc.${tld}`
  xsUri = `https://dev.x.${tld}`
  networkName = 'kovan'
} else if (networkName === 'staging') {
  webUri = `https://staging.${tld}`
  apiUri = `https://staging.api.${tld}`
  rpcUri = `https://mainnet.rpc.${tld}`
  xsUri = `https://staging.x.${tld}`
  networkName = 'mainnet'
}

const getNetworkId = (network) => {
  return {
    'mainnet': 1,
    'ropsten': 3,
    'rinkeby': 4,
    'kovan': 42,
    'goerli': 5
  }[network] || 1
}

export const blocknativeDappId = 'fccf560b-943a-45e9-9af3-6b19e44e167e'
export const networkId = getNetworkId(networkName)

export {
  webUri,
  apiUri,
  rpcUri,
  xsUri,
  networkName,
  authereumVersions
}
