import { networkName } from '../config'

const prefix = `@authereum/example/${networkName}:::`

export const setItem = (key, value) => {
  return localStorage.setItem(prefix + key, value)
}

export const getItem = (key) => {
  return localStorage.getItem(prefix + key)
}
