import React from 'react'
import Card from './Card'
import TextFieldFormGroup from './TextFieldFormGroup'
import TextValueFormGroup from './TextValueFormGroup'
import ButtonFormGroup from './ButtonFormGroup'
import localStorage from '../storage'

export default class Component extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      token: localStorage.getItem('tokenTransfer::token') || '0x7d669A64deb8a4A51eEa755bb0E19FD39CE25Ae9',
      to: localStorage.getItem('tokenTransfer::to') || '0x22d491Bde2303f2f43325b2108D26f1eAbA1e32b',
      amount: localStorage.getItem('tokenTransfer::amount') || '0.1',
    }
  }

  render() {
    return (
      <Card
        title={'ERC20: Transfer'}
        subheader={'token.transfer(to, amount)'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label="Token"
            placeholder="Enter token address"
            value={this.state.token}
            onChange={event => {
              const token = event.target.value
              localStorage.setItem('tokenTransfer::token', token)
              this.setState({token})
            }}
          />
          <TextFieldFormGroup
            label="To"
            placeholder="Enter to address"
            value={this.state.to}
            onChange={event => {
              const to = event.target.value
              localStorage.setItem('tokenTransfer::to', to)
              this.setState({to})
            }}
          />
          <TextFieldFormGroup
            label="Amount"
            placeholder="Enter amount"
            value={this.state.amount}
            onChange={event => {
              const amount = event.target.value
              localStorage.setItem('tokenTransfer::amount', amount)
              this.setState({amount})
            }}
          />
          <TextValueFormGroup
            label="Transaction hash"
            value={this.props.transactionHash}
          />
          <ButtonFormGroup>
            Transfer
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
