import React from 'react'
import { Authereum, version as latestVersion } from 'authereum'
import Web3 from 'web3'
import pify from 'pify'
import etherConverter from 'ether-converter'
import fromWei from '@authereum/utils/core/fromWei'
import toWei from '@authereum/utils/core/toWei'
import sha3 from '@authereum/utils/core/sha3'
import toHex from '@authereum/utils/core/toHex'
import getQueryParam from '@authereum/utils/core/getQueryParam'
import { theme } from '@authereum/mui-theme'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import SiteHeader from './components/SiteHeader'
import SiteFooter from './components/SiteFooter'
import SiteLoader from './components/SiteLoader'

import ProviderEnableWidget from './components/ProviderEnableWidget'
import ProviderDisableWidget from './components/ProviderDisableWidget'

import IsConnectedWidget from './components/IsConnectedWidget'
import GetBalanceWidget from './components/GetBalanceWidget'
import GetCoinbaseWidget from './components/GetCoinbaseWidget'
import GetAccountsWidget from './components/GetAccountsWidget'
import GetTransactionReceiptWidget from './components/GetTransactionReceiptWidget'

import SignWidget from './components/SignWidget'
import PersonalSignWidget from './components/PersonalSignWidget'
import SignTypedDataWidget from './components/SignTypedDataWidget'
import SignTypedDataV3Widget from './components/SignTypedDataV3Widget'

import SignTransactionWidget from './components/SignTransactionWidget'
import SendTransactionWidget from './components/SendTransactionWidget'
import SendTransactionBatchWidget from './components/SendTransactionBatchWidget'

import TokenBalanceOfWidget from './components/TokenBalanceOfWidget'
import TokenAllowanceWidget from './components/TokenAllowanceWidget'
import TokenApproveWidget from './components/TokenApproveWidget'
import TokenTransferWidget from './components/TokenTransferWidget'
import TokenTransferFromWidget from './components/TokenTransferFromWidget'
import TokenMintWidget from './components/TokenMintWidget'
import TokenBurnWidget from './components/TokenBurnWidget'

import NftOwnerOfWidget from './components/NftOwnerOfWidget'
import NftGetApprovedWidget from './components/NftGetApprovedWidget'
import NftApproveWidget from './components/NftApproveWidget'
import NftTransferFromWidget from './components/NftTransferFromWidget'
import NftSafeTransferFromWidget from './components/NftSafeTransferFromWidget'

import ValidateSignatureWidget from './components/ValidateSignatureWidget'
import EstimateGasWidget from './components/EstimateGasWidget'
import EstimateGasBatchWidget from './components/EstimateGasBatchWidget'
import EtherConverterWidget from './components/EtherConverterWidget'
import SignMessageWithSigningKeyWidget from './components/SignMessageWithSigningKeyWidget'
import ContractDeployedWidget from './components/ContractDeployedWidget'
import AddressQrCodeWidget from './components/AddressQrCodeWidget'
import WidgetToggleWidget from './components/WidgetToggleWidget'
import VersionWidget from './components/VersionWidget'
import AddFundsWidget from './components/AddFundsWidget'

import erc20Abi from './abi/ERC20'
import erc721Abi from './abi/ERC721'
import erc1271Abi from './abi/ERC1271'
import {
  webUri,
  apiUri,
  rpcUri,
  xsUri,
  networkName,
  authereumVersions
} from './config'

const muiTheme = createMuiTheme(theme)

class App extends React.Component {
  constructor (props) {
    super(props)

    let sdkVersion = getQueryParam('version')
    if (!sdkVersion || !authereumVersions.includes(sdkVersion)) {
      sdkVersion = latestVersion
    }

    this.state = {
      sdkVersion,
      loading: true,
      account: null,

      headerProps: {
        networkName,
        sdkVersion
      },

      providerEnableOutput: {},
      providerDisableOutput: {},

      isConnectedOutput: {},
      getBalanceOutput: {},
      getCoinbaseOutput: {},
      getAccountsOutput: {},
      getTransactionReceiptOutput: {},

      signOutput: {},
      personalSignOutput: {},
      signTypedDataOutput: {},
      signTypedDataV3Output: {},
      signTransactionOutput: {},
      sendTransactionOutput: {},
      sendTransactionBatchOutput: {},

      tokenBalanceOfOutput: {},
      tokenAllowanceOutput: {},
      tokenApproveOutput: {},
      tokenTransferOutput: {},
      tokenTransferFromOutput: {},
      tokenMintOutput: {},
      tokenBurnOutput: {},

      nftOwnerOfOutput: {},
      nftGetApprovedOutput: {},
      nftApproveOutput: {},
      nftTransferFromOutput: {},
      nftSafeTransferFromOutput: {},

      validateSignatureOutput: {},
      estimateGasOutput: {},
      estimateGasBatchOutput: {},
      EtherConverterWidget: {},
      signMessageWithAdminKeyOutput: {},
      signMessageWithSigningKeyOutput: {},
      contractDeployedOutput: {},
      addressQrCodeOutput: {},
      widgetToggleOutput: {},
      versionOutput: {},
      addFundsOutput: {}
    }

    this.init()
  }

  async init () {
    try {
      const { sdkVersion } = this.state
      console.log('sdk version:', sdkVersion)

      if (sdkVersion !== latestVersion) {
        const cdnUrl = `https://cdn.jsdelivr.net/npm/authereum@${sdkVersion}/authereum.js`
        await this.injectScript(cdnUrl)
      } else {
        window.Authereum = Authereum
      }

      const authereum = new window.Authereum({
        webUri,
        apiUri,
        rpcUri,
        xsUri,
        networkName,
        disableNotifications: false
      })

      const provider = authereum.getProvider()
      this.web3 = new Web3(provider)

      window.authereum = authereum
      window.ethereum = provider
      window.web3 = this.web3

      authereum
        .on('ready', () => {
          console.log('ready')
          this.refreshAccount()
        })
        .on('iframeReady', () => {
          console.log('iframeReady')
        })
        .on('openPopup', () => {
          console.log('openPopup')
        })
        .on('closePopup', () => {
          console.log('closePopup')
          this.refreshAccount()
        })
        .on('popupBlocked', () => {
          console.log('popupBlocked')
        })
        .on('login', () => {
          console.log('login')
          this.refreshAccount()
        })
        .on('logout', () => {
          console.log('logout')
          this.refreshAccount()
        })
        .on('dappKeyExpired', () => {
          console.log('dappKeyExpired')
        })
        .on('error', (err) => {
          console.log('error', err)
        })
        .showWidget()
    } catch (err) {
      console.error(err)
    }
  }

  injectScript = async (sourceUrl, id = '') => {
    return new Promise((resolve, reject) => {
      if (!sourceUrl) {
        throw new Error('sourceUrl is required')
      }

      if (id && document.getElementById(id)) {
        resolve()
        return
      }

      const script = document.createElement('script')
      script.id = id
      script.type = 'text/javascript'
      script.async = true
      script.onload = () => {
        resolve()
      }

      script.onerror = (err) => {
        reject(err)
      }

      script.src = sourceUrl
      document.getElementsByTagName('head')[0].appendChild(script)
    })
  }

  render () {
    return (
      <MuiThemeProvider theme={muiTheme}>
        {this.renderLoader()}
        {this.renderHeader()}
        {this.renderWidgets()}
        {this.renderFooter()}
      </MuiThemeProvider>
    )
  }

  renderHeader () {
    return (
      <SiteHeader {...this.state.headerProps} />
    )
  }

  renderFooter () {
    return (
      <SiteFooter />
    )
  }

  renderLoader () {
    if (!this.state.loading) return null

    return (
      <SiteLoader />
    )
  }

  renderWidgets () {
    return (
      <Grid item style={{
        padding: '1em'
      }}>
        <Grid container>
          <ProviderEnableWidget onSubmit={this.providerEnable} {...this.state.providerEnableOutput} />
          <ProviderDisableWidget onSubmit={this.providerDisable} {...this.state.providerDisableOutput} />

          <IsConnectedWidget onSubmit={this.isConnected} {...this.state.isConnectedOutput} />
          <GetBalanceWidget onSubmit={this.getBalance} {...this.state.getBalanceOutput} />
          <GetCoinbaseWidget onSubmit={this.getCoinbase} {...this.state.getCoinbaseOutput} />
          <GetAccountsWidget onSubmit={this.getAccounts} {...this.state.getAccountsOutput} />
          <GetTransactionReceiptWidget onSubmit={this.getTransactionReceipt} {...this.state.getTransactionReceiptOutput} />

          <SignWidget onSubmit={this.sign} {...this.state.signOutput} />
          <PersonalSignWidget onSubmit={this.personalSign} {...this.state.personalSignOutput} />
          <SignTypedDataWidget onSubmit={this.signTypedData} {...this.state.signTypedDataOutput} />
          <SignTypedDataV3Widget onSubmit={this.signTypedDataV3} {...this.state.signTypedDataV3Output} />

          <SignTransactionWidget onSubmit={this.signTransaction} {...this.state.signTransactionOutput} />
          <SendTransactionWidget onSubmit={this.sendTransaction} {...this.state.sendTransactionOutput} />
          <SendTransactionBatchWidget onSubmit={this.sendTransactionBatch} {...this.state.sendTransactionBatchOutput} />

          <TokenBalanceOfWidget onSubmit={this.tokenBalanceOf} {...this.state.tokenBalanceOfOutput} />
          <TokenAllowanceWidget onSubmit={this.tokenAllowance} {...this.state.tokenAllowanceOutput} />
          <TokenApproveWidget onSubmit={this.tokenApprove} {...this.state.tokenApproveOutput} />
          <TokenTransferWidget onSubmit={this.tokenTransfer} {...this.state.tokenTransferOutput} />
          <TokenTransferFromWidget onSubmit={this.tokenTransferFrom} {...this.state.tokenTransferFromOutput} />
          <TokenMintWidget onSubmit={this.tokenMint} {...this.state.tokenMintOutput} />
          <TokenBurnWidget onSubmit={this.tokenBurn} {...this.state.tokenBurnOutput} />

          <NftOwnerOfWidget onSubmit={this.nftOwnerOf} {...this.state.nftOwnerOfOutput} />
          <NftGetApprovedWidget onSubmit={this.nftGetApproved} {...this.state.nftGetApprovedOutput} />
          <NftApproveWidget onSubmit={this.nftApprove} {...this.state.nftApproveOutput} />
          <NftTransferFromWidget onSubmit={this.nftTransferFrom} {...this.state.nftTransferFromOutput} />
          <NftSafeTransferFromWidget onSubmit={this.nftSafeTransferFrom} {...this.state.nftSafeTransferFromOutput} />

          <ValidateSignatureWidget onSubmit={this.validateSignature} {...this.state.validateSignatureOutput} />
          <EstimateGasWidget onSubmit={this.estimateGas} {...this.state.estimateGasOutput} />
          <EstimateGasBatchWidget onSubmit={this.estimateGasBatch} {...this.state.estimateGasBatchOutput} />
          <EtherConverterWidget onSubmit={this.etherConverter} {...this.state.etherConverterOutput} />
          <SignMessageWithSigningKeyWidget onSubmit={this.signMessageWithSigningKey} {...this.state.signMessageWithSigningKeyOutput} />
          <ContractDeployedWidget onSubmit={this.isContractDeployed} {...this.state.contractDeployedOutput} />
          <AddressQrCodeWidget onSubmit={this.addressQrCode} {...this.state.addressQrCodeOutput} />
          <WidgetToggleWidget onSubmit={this.widgetToggle} {...this.state.widgetToggleOutput} />

          <VersionWidget onSubmit={this.version} {...this.state.versionOutput} />

          <AddFundsWidget onSubmit={this.addFunds} {...this.state.addFundsOutput} />
        </Grid>
      </Grid>
    )
  }

  refreshAccount = async () => {
    try {
      if (!this.web3) return

      const accounts = await this.web3.eth.getAccounts()
      const account = accounts.length > 0 ? accounts[0] : null
      const enabled = this.web3.currentProvider.isConnected()

      this.setState({
        account,
        providerEnableOutput: {
          enabled
        },
        providerDisableOutput: {
          disabled: !enabled
        },
        widgetToggleOutput: {
          enabled: this.web3.currentProvider.widgetEnabled()
        },
        loading: false
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  providerEnable = async () => {
    try {
      await this.web3.currentProvider.enable()
      const enabled = this.web3.currentProvider.isConnected()
      this.setState({
        providerEnableOutput: {
          enabled
        },
        providerDisableOutput: {
          enabled: !enabled
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  providerDisable = async () => {
    try {
      await this.web3.currentProvider.disable()
      const disabled = !this.web3.currentProvider.isConnected()
      this.setState({
        providerDisableOutput: {
          disabled
        },
        providerEnableOutput: {
          disabled: !disabled
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  isConnected = async (input) => {
    try {
      const connected = await this.web3.currentProvider.isConnected()

      this.setState({
        isConnectedOutput: {
          connected
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  getBalance = async (input) => {
    try {
      const { account } = input
      const balance = await this.web3.eth.getBalance(account)

      this.setState({
        getBalanceOutput: {
          value: fromWei(balance, 'ether')
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  getCoinbase = async () => {
    try {
      const coinbase = await this.web3.eth.getCoinbase()

      this.setState({
        getCoinbaseOutput: {
          coinbase
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  getAccounts = async () => {
    try {
      const accounts = await this.web3.eth.getAccounts()

      this.setState({
        getAccountsOutput: {
          accounts
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  getTransactionReceipt = async (input) => {
    try {
      const { transactionHash } = input
      const receipt = await this.web3.currentProvider.getTransactionReceipt(transactionHash)

      this.setState({
        getTransactionReceiptOutput: {
          receipt
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  sign = async (input) => {
    try {
      this.requireLogin()

      const { message } = input
      const signature = await this.web3.eth.sign(message, this.state.account)

      this.setState({
        signOutput: {
          signature
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  personalSign = async (input) => {
    try {
      this.requireLogin()

      const { message } = input

      const signature = await this.web3.eth.personal.sign(message, this.state.account)
      this.setState({
        personalSignOutput: {
          signature
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  signTypedData = async (input) => {
    try {
      this.requireLogin()

      const { data } = input
      const payload = JSON.parse(data)
      const from = this.state.account

      const params = [payload, from]
      const method = 'eth_signTypedData'

      await this.web3.currentProvider.sendAsync({
        id: 1,
        method,
        params,
        from
      }, (err, res) => {
        if (err) {
          throw err
        }

        const signature = res.result

        this.setState({
          signTypedDataOutput: {
            signature
          }
        })
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  signTypedDataV3 = async (input) => {
    try {
      this.requireLogin()

      const { data } = input
      const payload = JSON.parse(data)
      const from = this.state.account

      const params = [payload, from]
      const method = 'eth_signTypedData'

      this.web3.currentProvider.sendAsync({
        id: 1,
        method,
        params,
        from
      }, (err, res) => {
        if (err) {
          throw err
        }

        const signature = res.result

        this.setState({
          signTypedDataV3Output: {
            signature
          }
        })
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  signTransaction = async (input) => {
    try {
      this.requireLogin()

      const { to, value, data, gasPrice, gasLimit } = input

      const tx = {
        from: this.state.account,
        to
      }

      if (value !== '') {
        tx.value = toWei(value, 'ether')
      }

      if (data !== '') {
        tx.data = data
      }

      if (gasPrice !== '') {
        tx.gasPrice = gasPrice
      }

      if (gasLimit !== '') {
        tx.gas = gasLimit
      }

      const { raw } = await this.web3.eth.signTransaction(tx)
      this.setState({
        signTransactionOutput: {
          signedTransaction: raw,
          transactionHash: sha3(raw)
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  sendTransaction = async (input) => {
    try {
      this.requireLogin()

      const { to, value, data, gasPrice, gasLimit } = input

      const tx = {
        from: this.state.account,
        to
      }

      if (value !== '') {
        tx.value = toWei(value, 'ether')
      }

      if (data !== '') {
        tx.data = data
      }

      if (gasPrice !== '') {
        tx.gasPrice = gasPrice
      }

      if (gasLimit !== '') {
        tx.gas = gasLimit
      }

      const transactionHash = await pify(this.web3.eth.sendTransaction)(tx)
      this.setState({
        sendTransactionOutput: {
          transactionHash
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  sendTransactionBatch = async (input) => {
    try {
      this.requireLogin()

      const { transactions } = input
      const { transactionHash } = await this.web3.currentProvider.sendTransactionBatch(transactions)

      this.setState({
        sendTransactionBatchOutput: {
          transactionHash
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  tokenBalanceOf = async (input) => {
    try {
      const { token, account } = input

      const instance = await new this.web3.eth.Contract(erc20Abi, token)
      const value = await instance.methods.balanceOf(account).call()
      const decimals = await instance.methods.decimals().call()
      const balance = fromWei(value, +decimals)

      this.setState({
        tokenBalanceOfOutput: {
          balance
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  tokenAllowance = async (input) => {
    try {
      const { token, owner, spender } = input

      const instance = await new this.web3.eth.Contract(erc20Abi, token)
      const decimals = await instance.methods.decimals().call()

      const value = await instance.methods.allowance(owner, spender).call()
      const allowance = fromWei(value, +decimals)

      this.setState({
        tokenAllowanceOutput: {
          allowance
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  tokenApprove = async (input) => {
    try {
      this.requireLogin()

      const { token, spender, amount } = input

      const instance = await new this.web3.eth.Contract(erc20Abi, token)
      const decimals = await instance.methods.decimals().call()
      const value = toWei(amount, +decimals)

      const transactionHash = await pify(instance.methods.approve(spender, value).send)({
        from: this.state.account
      })

      this.setState({
        tokenApproveOutput: {
          transactionHash
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  tokenTransfer = async (input) => {
    try {
      this.requireLogin()

      const { token, to, amount } = input

      const instance = await new this.web3.eth.Contract(erc20Abi, token)
      const decimals = await instance.methods.decimals().call()
      const value = toWei(amount, +decimals)

      const transactionHash = await pify(instance.methods.transfer(to, value).send)({
        from: this.state.account
      })

      this.setState({
        tokenTransferOutput: {
          transactionHash
        }
      })
    } catch (err) {
      // NOTE: The only way for this to fail is if a non-token address is used
      const error = new Error('This is not a token address')
      this.handleError(error)
    }
  }

  tokenTransferFrom = async (input) => {
    try {
      this.requireLogin()

      const { token, from, to, amount } = input

      const instance = await new this.web3.eth.Contract(erc20Abi, token)
      const decimals = await instance.methods.decimals().call()
      const value = toWei(amount, +decimals)

      const transactionHash = await pify(instance.methods.transferFrom(from, to, value).send)({
        from: this.state.account
      })

      this.setState({
        tokenTransferFromOutput: {
          transactionHash
        }
      })
    } catch (err) {
      // NOTE: The only way for this to fail is if a non-token address is used
      const error = new Error('This is not a token address')
      this.handleError(error)
    }
  }

  tokenMint = async (input) => {
    try {
      this.requireLogin()

      const { token, to, amount } = input

      const instance = await new this.web3.eth.Contract(erc20Abi, token)
      const decimals = await instance.methods.decimals().call()
      const value = toWei(amount, +decimals)

      const transactionHash = await pify(instance.methods.mint(to, value).send)({
        from: this.state.account
      })

      this.setState({
        tokenMintOutput: {
          transactionHash
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  tokenBurn = async (input) => {
    try {
      this.requireLogin()

      const { token, amount } = input

      const instance = await new this.web3.eth.Contract(erc20Abi, token)
      const decimals = await instance.methods.decimals().call()
      const value = toWei(amount, +decimals)

      const transactionHash = await pify(instance.methods.burn(value).send)({
        from: this.state.account
      })

      this.setState({
        tokenBurnOutput: {
          transactionHash
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  nftOwnerOf = async (input) => {
    try {
      const { token, tokenId } = input

      const instance = await new this.web3.eth.Contract(erc721Abi, token)
      const owner = await instance.methods.ownerOf(tokenId).call()

      this.setState({
        nftOwnerOfOutput: {
          owner
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  nftGetApproved = async (input) => {
    try {
      const { token, tokenId } = input

      const instance = await new this.web3.eth.Contract(erc721Abi, token)
      const to = await instance.methods.getApproved(tokenId).call()

      this.setState({
        nftGeApprovedOutput: {
          to
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  nftApprove = async (input) => {
    try {
      this.requireLogin()

      const { token, to, tokenId } = input

      const instance = await new this.web3.eth.Contract(erc721Abi, token)

      const transactionHash = await pify(instance.methods.approve(to, tokenId).send)({
        from: this.state.account
      })

      this.setState({
        nftApproveOutput: {
          transactionHash
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  nftTransferFrom = async (input) => {
    try {
      this.requireLogin()

      const { token, from, to, tokenId } = input

      const instance = await new this.web3.eth.Contract(erc721Abi, token)
      const transactionHash = await pify(instance.methods.transferFrom(from, to, tokenId).send)({
        from: this.state.account
      })

      this.setState({
        nftTransferFromOutput: {
          transactionHash
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  nftSafeTransferFrom = async (input) => {
    try {
      this.requireLogin()

      const { token, from, to, tokenId, data } = input

      const instance = await new this.web3.eth.Contract(erc721Abi, token)
      const transactionHash = await pify(instance.methods.safeTransferFrom(from, to, tokenId, data).send)({
        from: this.state.account
      })

      this.setState({
        nftSafeTransferFromOutput: {
          transactionHash
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  validateSignature = async (input) => {
    try {
      let { account, data, signature } = input

      data = toHex(data)
      signature = toHex(signature)

      const magicValue = '0x20c13b0b'
      const instance = await new this.web3.eth.Contract(erc1271Abi, account)
      const result = await instance.methods.isValidSignature(data, signature).call()
      const valid = (result.toLowerCase() === magicValue)

      this.setState({
        validateSignatureOutput: {
          valid
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  estimateGas = async (input) => {
    const { from, to, value, data } = input
    const tx = {
      from: toHex(from),
      to: toHex(to),
      value: toHex(value),
      data: toHex(data)
    }

    try {
      const estimatedGas = await this.web3.eth.estimateGas(tx)
      this.setState({
        estimateGasOutput: {
          estimatedGas
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  estimateGasBatch = async (input) => {
    try {
      this.requireLogin()

      const { transactions } = input
      const estimatedGas = await this.web3.currentProvider.estimateGasBatch(transactions)

      this.setState({
        estimateGasBatchOutput: {
          estimatedGas: JSON.stringify(estimatedGas)
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  etherConverter = async (input) => {
    const { value, unit } = input

    try {
      const converted = await etherConverter(value, unit)
      this.setState({
        etherConverterOutput: {
          converted
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  signMessageWithSigningKey = async (input) => {
    try {
      this.requireLogin()

      const { message } = input
      const signature = await this.web3.currentProvider.signMessageWithSigningKey(message)

      this.setState({
        signMessageWithSigningKeyOutput: {
          signature
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  isContractDeployed = async (input) => {
    try {
      const { account } = input
      const deployed = await this.web3.currentProvider.isContractDeployed(account)

      this.setState({
        contractDeployedOutput: {
          deployed
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  widgetToggle = async (input) => {
    try {
      const { enabled } = input
      this.web3.currentProvider.showWidget(enabled)

      this.setState({
        widgetToggleOutput: {
          enabled
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  version = async () => {
    try {
      const version = this.web3.currentProvider.authereum.version()

      this.setState({
        versionOutput: {
          version
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  addFunds = async () => {
    try {
      await this.web3.currentProvider.authereum.addFunds()
    } catch (err) {
      this.handleError(err)
    }
  }

  addressQrCode = async (input) => {
    try {
      const { address } = input
      const qrCodeDataUri = await this.web3.currentProvider.authereum.getAddressQrCodeDataUri(address)

      this.setState({
        addressQrCodeOutput: {
          qrCodeDataUri
        }
      })
    } catch (err) {
      this.handleError(err)
    }
  }

  requireLogin = () => {
    if (!this.web3.currentProvider.isConnected()) {
      throw new Error('Please enable account first')
    }
  }

  handleError = (err) => {
    if (err && err.message) {
      console.error(err)
    }
  }
}

export default App
