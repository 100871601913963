import React from 'react'
import Card from './Card'
import TextFieldFormGroup from './TextFieldFormGroup'
import TextValueFormGroup from './TextValueFormGroup'
import ButtonFormGroup from './ButtonFormGroup'
import localStorage from '../storage'

export default class Component extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      message: localStorage.getItem('signWithSigningKey::message') || 'hello world'
    }
  }

  render() {
    return (
      <Card
        title={'Authereum: Signing Key Sign Message'}
        subheader={'authereum.signMessageWithSigningKey(data)'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label="Message"
            placeholder="Enter message"
            multiline={true}
            value={this.state.message}
            onChange={event => {
              const message = event.target.value
              this.setState({message})
              localStorage.setItem('signWithSigningKey::message', message)
            }}
          />
          <TextValueFormGroup
            label="Signature"
            value={this.props.signature}
          />
          <ButtonFormGroup>
            Sign with signing key
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
