import React from 'react'
import Card from './Card'
import TextFieldFormGroup from './TextFieldFormGroup'
import TextValueFormGroup from './TextValueFormGroup'
import ButtonFormGroup from './ButtonFormGroup'
import localStorage from '../storage'

export default class Component extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      to: localStorage.getItem('sendTransaction::to') || '0x22d491Bde2303f2f43325b2108D26f1eAbA1e32b',
      value: localStorage.getItem('sendTransaction::value') || '0.01',
      data: localStorage.getItem('sendTransaction::data') || '',
      gasPrice: localStorage.getItem('sendTransaction::gasPrice') || '',
      gasLimit: localStorage.getItem('sendTransaction::gasLimit') || ''
    }
  }

  render() {
    return (
      <Card
        title={'Send Transaction'}
        subheader={'web3.eth.sendTransaction({from, to, value, data})'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextFieldFormGroup
            label="To"
            placeholder="Enter to address"
            value={this.state.to}
            onChange={event => {
              const to = event.target.value
              localStorage.setItem('sendTransaction::to', to)
              this.setState({to})
            }}
          />
          <TextFieldFormGroup
            label="Value (ETH)"
            placeholder="Enter ETH value"
            value={this.state.value}
            onChange={event => {
              const value = event.target.value
              localStorage.setItem('sendTransaction::value', value)
              this.setState({value})
            }}
          />
          <TextFieldFormGroup
            label="Data"
            multiline={true}
            rowsMax={5}
            placeholder="Enter data"
            value={this.state.data}
            onChange={event => {
              const data = event.target.value
              localStorage.setItem('sendTransaction::data', data)
              this.setState({data})
            }}
          />
          <TextFieldFormGroup
            label="Gas Price"
            placeholder="Enter gas price"
            value={this.state.gasPrice}
            onChange={event => {
              const gasPrice = event.target.value
              localStorage.setItem('sendTransaction::gasPrice', gasPrice)
              this.setState({gasPrice})
            }}
          />
          <TextFieldFormGroup
            label="Gas Limit"
            placeholder="Enter gas limit"
            value={this.state.gasLimit}
            onChange={event => {
              const gasLimit = event.target.value
              localStorage.setItem('sendTransaction::gasLimit', gasLimit)
              this.setState({gasLimit})
            }}
          />
          <TextValueFormGroup
            label="Transaction hash"
            value={this.props.transactionHash}
          />
          <ButtonFormGroup>
            Send transaction
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
