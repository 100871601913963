import React from 'react'
import Card from './Card'
import TextValueFormGroup from './TextValueFormGroup'
import ButtonFormGroup from './ButtonFormGroup'

export default class Component extends React.Component {
  render() {
    return (
      <Card
        title={'Accounts'}
        subheader={'web3.eth.getAccounts()'}>
        <form
          noValidate
          onSubmit={this.handleSubmit}>
          <TextValueFormGroup
            label="Accounts"
            value={JSON.stringify(this.props.accounts)}
          />
          <ButtonFormGroup>
            Get accounts
          </ButtonFormGroup>
        </form>
      </Card>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }
}
